/* eslint-disable no-console */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState } from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import { CSSTransition } from 'react-transition-group'
import parse from 'html-react-parser'

// Language
import { getLanguage } from 'services/language'

// Components
import CustomLink from 'components/shared/CustomLink'
import LanguageSwitch from 'components/LanguageSwitch'
import ButtonDefault from 'components/elements/ButtonDefault'

// Images
import imgLogo from 'img/logo_new.svg'
import phone from 'img/phone.svg'
import arrowUp from 'img/arrow-up.svg'

// Icons
import chevronDown from 'img/down-arrow.svg'

const StyledHeader = styled.nav`
  background-color: ${(props) => props.theme.color.light};
  position: relative;
  padding: 15px 0;
`

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Brand = styled(CustomLink)`
  & > img {
    height: 60px;
    width: auto;
  }
`

const NavButton = styled.button`
  position: absolute;
  top: 15px;
  right: 15px;
  width: 65px;
  height: 40px;
`

const NavButtonStripe = styled.div`
  background-color: ${(props) => props.theme.color.text.main};
  height: 2px;
  width: 100%;
  margin: 5px auto;
  max-width: 80%;
`

const NavButtonTitle = styled.div`
  color: ${(props) => props.theme.color.text.main};
  font-size: ${(props) => props.theme.font.size.sm};
  font-weight: ${(props) => props.theme.font.weight.l};
  text-transform: uppercase;
  margin: 0 auto;
`

const Header = () => {
  const {
    allWordpressWpComponenten: { edges: headers },
    allWordpressPage: { edges: pages },
    contact: {
      acf: { phonenumber },
    },
  } = useStaticQuery(graphql`
    {
      allWordpressWpComponenten(filter: { wordpress_id: { in: [86, 474] } }) {
        edges {
          node {
            wpId: wordpress_id
            acf {
              links {
                wpId: wordpress_id
                title
                path
              }
            }
          }
        }
      }
      allWordpressPage {
        edges {
          node {
            wordpress_id
            title
            path
            parent: parent_element {
              wpId: wordpress_id
            }
          }
        }
      }
      contact: wordpressPage(wordpress_id: { eq: 9 }) {
        acf {
          phonenumber
        }
      }
    }
  `)

  const language = getLanguage()

  const {
    node: {
      acf: { links: items },
    },
  } = headers.find(
    ({ node: { wpId } }) => wpId === (language === 'nl_NL' ? 86 : 474)
  )

  const [isCollapsed, setIsCollapsed] = useState(true)

  return (
    <StyledHeader>
      <Container className="container">
        <Brand to={language === 'nl_NL' ? '/' : '/pl/'}>
          <img src={imgLogo} alt="" />
        </Brand>

        <NavButton
          type="button"
          onClick={() => setIsCollapsed(!isCollapsed)}
          className="d-block d-lg-none"
        >
          <NavButtonStripe />
          <NavButtonStripe />
          <NavButtonStripe />
          <NavButtonTitle>Menu</NavButtonTitle>
        </NavButton>

        <HeaderMenuMobile
          items={items}
          pages={pages}
          isCollapsed={isCollapsed}
          phonenumber={phonenumber}
          setIsCollapsed={setIsCollapsed}
        />
        <HeaderMenuDesktop
          items={items}
          pages={pages}
          phonenumber={phonenumber}
        />
      </Container>
    </StyledHeader>
  )
}

const StyledTransition = styled(CSSTransition)`
  &-enter {
    opacity: 0;
  }

  &-enter-active {
    opacity: 1 !important;
    transition: opacity 300ms, transform 300ms;
  }

  &-exit {
    opacity: 1;
  }

  &-exit-active {
    opacity: 0;
    transition: opacity 300ms, transform 300ms;
  }
`

const Submenu = styled.div`
  background-color: ${(props) => props.theme.color.light};
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.5);

  & > span {
    position: absolute;
    left: 20px;
    top: 0;
    box-sizing: border-box;
    z-index: 10;

    border: 7px solid black;
    border-color: ${(props) => props.theme.color.light}
      ${(props) => props.theme.color.light} transparent transparent;

    transform-origin: 0 0;
    transform: rotate(-45deg);

    box-shadow: 3px -3px 3px -3px rgba(0, 0, 0, 0.3);
    z-index: 1;
  }

  & > div {
    position: relative;
    height: 100%;
    width: 100%;
    z-index: 2;
    display: flex;
    padding: 10px 0;
  }

  @media (min-width: 992px) {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 10;
  }

  @media (max-width: 991px) {
    & > a > div {
      font-size: ${(props) => props.theme.font.size.xm};
    }
  }
`

const SubmenuItem = styled(CustomLink)`
  color: ${(props) => props.theme.color.text.secondary};
  font-weight: ${(props) => props.theme.font.weight.m};
  padding: 5px 20px;
  min-width: 110px;

  &[aria-current='page'] {
    font-weight: ${(props) => props.theme.font.weight.l};
  }

  &:hover {
    color: ${(props) => props.theme.color.text.contrast};
    text-decoration: underline;
  }
`

const StyledHeaderMenuMobile = styled.div`
  background-color: ${(props) => props.theme.color.light};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;

  & ul {
    list-style-type: none;
    padding: 50px 0 0;
    width: 100%;
    text-align: center;

    & a {
      font-size: ${(props) => props.theme.font.size.xm};
    }
  }

  & ${Submenu} {
    box-shadow: none;

    & > span {
      display: none;
    }

    & > div {
      display: block;
    }

    & ${SubmenuItem} {
      font-size: ${(props) => props.theme.font.size.m};
      display: block;
    }
  }
`

const NavButtonClose = styled.div`
  position: relative;
  width: 32px;
  height: 32px;

  &:before,
  &:after {
    background-color: ${(props) => props.theme.color.text.secondary};
    position: absolute;
    content: ' ';
    height: 33px;
    width: 2px;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
`

const HeaderMenuMobile = ({
  items,
  pages,
  isCollapsed,
  setIsCollapsed,
  phonenumber,
}) => (
  <StyledTransition in={!isCollapsed} timeout={300} unmountOnExit>
    <StyledHeaderMenuMobile>
      <NavButton type="button" onClick={() => setIsCollapsed(!isCollapsed)}>
        <NavButtonClose />
      </NavButton>

      <ul>
        <HeaderMenuItems
          items={items}
          pages={pages}
          phonenumber={phonenumber}
        />
      </ul>
    </StyledHeaderMenuMobile>
  </StyledTransition>
)

const StyledHeaderMenuDesktop = styled.ul`
  flex: 1;
  align-items: center;
  justify-content: space-around;
  list-style: none;
  margin-bottom: 0;
`

const HeaderMenuDesktop = ({ items, pages, phonenumber }) => (
  <StyledHeaderMenuDesktop className="d-none d-lg-flex">
    <HeaderMenuItems items={items} pages={pages} phonenumber={phonenumber} />
  </StyledHeaderMenuDesktop>
)

const Item = styled.li`
  position: relative;
  padding: 15px 0;

  & > a {
    color: ${(props) => props.theme.color.text.secondary};

    @media (min-width: 992px) and (max-width: 1199px) {
      font-size: 12px;
    }

    &[aria-current] {
    }

    &:hover {
      color: ${(props) => props.theme.color.text.contrast};
      text-decoration: underline;
    }
  }

  & > span {
    color: ${(props) => props.theme.color.text.secondary};
    cursor: pointer;

    @media (min-width: 992px) and (max-width: 1199px) {
      font-size: 12px;
    }
  }

  &:hover > ${Submenu} {
    display: block;
  }
`

const Icon = styled.img`
  width: 10px;
  height: 10px;
  margin-left: 10px;
  position: relative;
  bottom: 1px;
`
const StyledButtonDefault = styled(ButtonDefault)`
  display: block;
  width: 140px;
  z-index: 9;

  & a::before {
    content: '';
    background: url(${arrowUp}) no-repeat;
    display: block;
    position: absolute;
    width: 24px;
    height: 24px;
    top: -10px;
    left: 50%;
    margin-left: -12px;
  }

  @media (min-width: 992px) {
    position: absolute;
    top: 92%;
    left: 90%;
    margin-left: -100px;
    margin-top: 5px;
  }

  @media (max-width: 991px) {
    position: relative;
    margin: 0 auto;
    margin-top: 10px;
  }
`

const ButtonIcon = styled.img`
  width: 23px;
  height: 23px;
`

const HeaderMenuItems = ({ items, pages, phonenumber }) => (
  <>
    {items.map(({ path, title, wpId }, key) => {
      let subPages = []

      if (pages) {
        subPages = pages.filter(
          ({ node }) =>
            node.parent &&
            node.parent.wpId !== 2 &&
            parseFloat(node.parent.wpId) === parseFloat(wpId)
        )
      }

      const hasSubPages = subPages && subPages.length > 0

      // Titel aanpassen indien nodig
      const adjustedTitle =
        title === 'Informatieavond voor Starters op de woningmarkt'
          ? 'Informatieavond'
          : title

      // add a extra menu item after key 3
      if (key === 3) {
        return (
          <>
            <Item key={wpId}>
              <CustomLink to={path}>
                {adjustedTitle}
                {hasSubPages && <Icon src={chevronDown} alt="" />}
              </CustomLink>
              {hasSubPages && (
                <Submenu>
                  <span />
                  <div>
                    {subPages.map(({ node }, index) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <SubmenuItem key={index} to={node.path}>
                        {parse(node.title)}
                      </SubmenuItem>
                    ))}
                  </div>
                </Submenu>
              )}
            </Item>
            <Item>
              <a
                href="https://expats.welbijwim.nl/"
                target="_blank"
                rel="noreferrer"
              >
                Expats
              </a>
            </Item>
          </>
        )
      }

      return (
        <Item key={wpId}>
          {wpId === 481 ? (
            <span>
              {title}
              {hasSubPages && <Icon src={chevronDown} alt="" />}
            </span>
          ) : (
            <CustomLink to={path}>
              {title}
              {hasSubPages && <Icon src={chevronDown} alt="" />}
            </CustomLink>
          )}
          {hasSubPages && (
            <Submenu>
              <span />
              <div>
                {subPages.map(({ node }, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <SubmenuItem key={index} to={node.path}>
                    {parse(node.title)}
                  </SubmenuItem>
                ))}
              </div>
            </Submenu>
          )}
          {[439, 9].indexOf(parseFloat(wpId)) !== -1 && (
            <StyledButtonDefault isAnchor to={`tel:${phonenumber}`}>
              <ButtonIcon className="mr-2" src={phone} alt="" />
              <p className="font-size-m font-family-secondary-alt mb-0">
                {parseFloat(wpId) === 439 ? 'Call' : 'Bel'}
                {' '}
                Wim
              </p>
            </StyledButtonDefault>
          )}
        </Item>
      )
    })}
    {/* <Item>
      <LanguageSwitch />
    </Item> */}
  </>
)

export default Header
