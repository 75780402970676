/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import CookieConsent from 'react-cookie-consent'

// Theme
import Theme from 'styles/Theme'
import CustomLink from 'components/shared/CustomLink'

// Components
import Footer from './Footer'
import Header from './Header'

// CSS
import 'styles/all.scss'

const Layout = ({ children }) => (
  <Theme>
    <Header />
    <>{children}</>
    <Footer />
  </Theme>
)

export default Layout
